import React from "react";
import ContactStyles from "./ContactUs.module.scss";
import Layout from "../components/Layout";
import ContactUsForm from "../components/ContactUs/ContactUsForm";
import { Helmet } from "react-helmet";

function Contact(props) {
  const { location } = props;
  const url = location.href ? location.href : "";

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Contact Hashout to start your Adobe Experience Manager project
        </title>
        <meta
          name="description"
          content="Contact Hashout (Adobe Partner with AEM Specialization) to accelerate your AEM feature releases and for support with Salesforce Communities."
        ></meta>
      </Helmet>
      <div className={ContactStyles.contentWrapper}>
        <div className={ContactStyles.header}>
          <h1>Contact Us</h1>
          <p>
            Call, email or submit the form to speak to one of our certified
            solution architects today!
          </p>
        </div>
        <div className={ContactStyles.contents}>
          <div className={ContactStyles.details}>
            <div className={ContactStyles.addressSection}>
              <img src="../../map.svg"></img>
              <div className={ContactStyles.addressWrapper}>
                <p className={ContactStyles.addressTitle}>US Address</p>
                <p>
                  8 S Second Street
                  <br /> San Jose, CA 95133
                </p>
                <div className={ContactStyles.divider}></div>
                <p className={ContactStyles.addressTitle}>India Address</p>
                <p>
                  91Springboard, 80 Feet Rd, Koramangala 8th Block, <br />
                  Bengaluru, Karnataka 560095
                </p>
              </div>
            </div>
            <div className={ContactStyles.callSection}>
              {/* <img src="../../call.svg"></img>
              <p className={ContactStyles.callNumber}>+1-703 646 7375</p> */}
            </div>
            <div className={ContactStyles.mailSection}>
              <img src="../../mail.svg"></img>
              <p className={ContactStyles.mailID}>
                <a href="mailto:contact@hashouttech.com">
                  contact@hashouttech.com
                </a>
              </p>
            </div>
          </div>
          <div className={ContactStyles.contactForm}>
            <p>
              Fill out this short form, and we'll get back to you within one
              working day.
            </p>
            <div className={ContactStyles.modalForm}>
              <ContactUsForm  url={url} page="Contact Us" section="Contact Us" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
